import React, { Component } from 'react'
import { Alert } from 'react-bootstrap';


class Footer extends Component {
    state = {  }
    render() { 
        return ( <div className='container text-center'>
            <Alert>Please click the {" "}
            <Alert.Link>
            Footer
            </Alert.Link>
            {" "} and be done with it.<span className='badge text-primary'>Okay</span>
            </Alert>
            
            
            </div> );
            
    }
}
 
export default Footer;


