import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';

class Counter extends Component {
    
    render() {

        return (
        <div>
               
                <span style={{fontSize:25}} className= {'display-2'} >
                     {this.props.counter.title} : <span className={this.getBadgeClasses()}>{this.formatCount()}</span>
                </span>

                <div>
                <Button 
                    onClick={() => this.props.onIncrease(this.props.counter)}
                    className= {this.getButtonClasses() + ' btn-success'}>
                        +
                </Button>
                <Button 
                    onClick={() => {this.props.onDecrease (this.props.counter)}} 
                    className={this.getButtonClasses() + ' btn-danger'}>
                        -
                </Button>
                <Button 
                    onClick={() => {this.props.onReset(this.props.counter)}} 
                    className="bg-info">
                        Reset
                </Button>
                <Button onClick={() => {this.props.onDelete(this.props.counter.id)}} className="btn btn-danger m-2">Delete</Button>
                </div>
        </div>
        );

    }

    getButtonClasses() {
        let classes = "m-1";
        return classes;
    }

    getBadgeClasses() {
        let classes = "m-2 btn btn-";
        classes += (this.props.counter.value < 1) ? "info" : "success";
        return classes;
    }

    formatCount() {
        const { value } = this.props.counter;
        return value === 0 ? 0 : value;
    }
}
 
export default Counter;