import React, { Component } from 'react'
import Counter from './counter'

export class counters extends Component {
  
    
    render() {
        
        const {onReset, onIncrease, onDelete, onDecrease, counters} = this.props;
        return (

        
        <div className='container text-center'>
        

            {counters.map(counter => (
                <Counter 
                key={counter.id} 
                onDelete={onDelete} 
                onIncrease={onIncrease} 
                onDecrease={onDecrease} 
                onReset={onReset} 
                counter= {counter}>
                <h4>{counter.title}</h4>
            </Counter>
            ))}

            <button 
                onClick={() => this.props.onResetAll()} 
                className="btn btn-danger m-2">
                Reset All
            </button>

            
        </div>
        
    )
  }
}

export default counters
