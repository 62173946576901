
import './App.css';
import React, { Component } from 'react';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Counters from './components/counters';
import Footer from './components/footer';
import Navbar from './components/navbar';

class App extends Component {
    
  state = {
      counters: [
          {id: 1, title: "Kaffee", value: 0},
          {id: 2, title: "Wasser", value: 0},
          {id: 3, title: "Cola", value: 0},
          {id: 4, title: "Bier", value: 0},
          {id: 5, title: "Sahne", value: 0},
      ]}

  handleDelete= (counterId) => {
    const counters = this.state.counters.filter(c => c.id !== counterId);
    this.setState({counters});
    }

  handleResetAll= () => {
    const counters = 
    [
      {id: 1, title: "Kaffee", value: 0},
      {id: 2, title: "Wasser", value: 0},
      {id: 3, title: "Cola", value: 0},
      {id: 4, title: "Bier", value: 0},
      {id: 5, title: "Sahne", value: 0},
  ]
      console.log('Reset')
      this.setState({counters})
      }

   handleReset= counter => {
    const counters = [...this.state.counters];
    const index = counters.indexOf(counter);
    counters[index] =  {...counter};
    counters[index].value = 0;
    this.setState({counters})
    }


   handleIncrease= counter => {
    const counters = [...this.state.counters];
    const index = counters.indexOf(counter);
    counters[index] =  {...counter};
    counters[index].value++;
    this.setState({counters})
    }

   handleDecrease= counter => {
    const counters = [...this.state.counters];
    const index = counters.indexOf(counter);
    counters[index] =  {...counter};
    if (counters[index].value > 0) {
      counters[index].value--;
    }
    this.setState({counters})
    };



  render() {
  return (
    <React.Fragment>
      
      <Navbar totalCounters= {this.state.counters.filter(c=> c.value > 0).length} />
      <main className='container'>
        <Counters
          onReset = { this.handleReset}
          onResetAll = { this.handleResetAll}
          onIncrease = { this.handleIncrease}
          onDecrease = { this.handleDecrease}
          onDelete = { this.handleDelete}
          counters= {this.state.counters}
        />
      </main>
      <Footer />
    </React.Fragment>
  );
}
}

export default App;
