import React from 'react'

const Navbar = ({totalCounters}) => {
    
        return ( 
        
        <div >
            <nav className="navbar bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" 
                href="/#">Navbar
                 <span className='badge bg-primary'>
                 {totalCounters}
                </span>
                </a>
            </div>                

            </nav>
        </div>);
    }
 
export default Navbar;


